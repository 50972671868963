'use client';

import { Box, Skeleton, Stack } from '@mui/material';
import clsx from 'clsx';
import ShowMoreText from 'react-show-more-text';
import { useEffect, useState } from 'react';

const StyledBox = ({
  textTransformStyle = 'none',
  ellipsis = false,
  children,
  ...rest
}) => {
  return (
    <Box
      {...rest}
      sx={{
        textTransform: textTransformStyle,
        whiteSpace: ellipsis ? 'nowrap' : 'normal',
        overflow: ellipsis ? 'hidden' : '',
        textOverflow: ellipsis ? 'ellipsis' : '',
      }}
    >
      {children}
    </Box>
  );
};

export default StyledBox;

export const H1 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="h1"
      mb={0}
      mt={0}
      fontSize="30px"
      fontWeight="700"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const H2 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="h2"
      mb={0}
      mt={0}
      fontSize="25px"
      fontWeight="700"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const H3 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      mb={0}
      mt={0}
      component="h3"
      fontSize="20px"
      fontWeight="700"
      lineHeight="1.5"
      ellipsis={ellipsis ? 1 : undefined}
      textTransformStyle={textTransform}
      className={clsx({
        [className || '']: true,
      })}
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const H4 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      mb={0}
      mt={0}
      component="h4"
      fontSize="17px"
      fontWeight="600"
      lineHeight="1.5"
      ellipsis={ellipsis ? 1 : undefined}
      textTransformStyle={textTransform}
      className={clsx({
        [className || '']: true,
      })}
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const H5 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="h5"
      mb={0}
      mt={0}
      fontSize="16px"
      fontWeight="600"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const H6 = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="h6"
      mb={0}
      mt={0}
      fontSize="14px"
      fontWeight="600"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const Paragraph = ({
  children,
  className,
  ellipsis,
  textTransform,
  truncate,
  ...props
}: any) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (truncate && !isMounted) {
    return (
      <Stack width="100%">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>
    );
  }
  return truncate ? (
    <ShowMoreText lines={truncate} anchorClass="show_more-button">
      {children}
    </ShowMoreText>
  ) : (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="p"
      mb={0}
      mt={0}
      fontSize="14px"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const Small = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="small"
      fontSize="12px"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const Span = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="span"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
export const Tiny = ({
  children,
  className,
  ellipsis,
  textTransform,
  ...props
}: any) => {
  return (
    <StyledBox
      textTransformStyle={textTransform}
      ellipsis={ellipsis ? 1 : undefined}
      className={clsx({
        [className || '']: true,
      })}
      component="small"
      fontSize="10px"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </StyledBox>
  );
};
