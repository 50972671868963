import { Button, Container, Stack } from '@mui/material';
import { H4, Small } from '@web/components/Typography';
import { Media } from '@web/media';
import dynamic from 'next/dynamic';
import { format, isValid } from 'date-fns';
import { FlexBox } from '@web/components/flex-box';
import DumbLink from "@web/components/DumbLink";
import NextImage from "next/image";
const ProductListMobile = dynamic(() =>
  import('@web/components/product-list/ProductListMobile')
);
const ShowMoreLayout = dynamic(() =>
  import('@web/components/product-list/ShowMoreLayout')
);
export default function ProductList({
  products = [],
  category = '',
  title = '',
  numberPerRow = 5,
  horizontal,
  ProductCard,
  link='',
  height=330,
}) {
  let maxDate = new Date(
    Math.max.apply(
      null,
      products?.map((p) => p.current_created_at).map((e) => new Date(e))
    )
  );

  return (
    <Container sx={{ my: 1 }}>
      <DumbLink href={link || `/category/${category}`}>
        <Media at="xs">
          <FlexBox justifyContent="space-between" my={2}>
            <Stack direction="row" alignItems="center">
              {title === 'OzBargain Deals' && (<NextImage
                src={`/images/oz.webp`}
                width={50}
                height={30}
                alt="ozbargain"
                style={{objectFit: 'contain'}}
              />)}
              <H4>{ title || `${category} Top Deals` } </H4>
            </Stack>
            <Button variant="text" color="primary" size="small">
              Show all
            </Button>
          </FlexBox>
        </Media>
        <Media greaterThan="xs">
          <FlexBox justifyContent="space-between" my={1}>
            <Stack direction="row" alignItems="center">
              {title === 'OzBargain Deals' && (<NextImage
                src={`/images/oz.webp`}
                width={50}
                height={30}
                alt="ozbargain"
                style={{objectFit: 'contain'}}
              />)}
              <H4>{ title || `${category} Top Deals Today` }</H4>
            </Stack>
            <Stack alignItems="flex-end">
              <Button variant="text" color="primary" size="small">
                Show all
              </Button>
              <Small>
                {isValid(maxDate) &&
                  `Last updated at ${format(maxDate, 'dd/MM/yyyy HH:mm:ss')}`}
              </Small>
            </Stack>
          </FlexBox>
        </Media>
      </DumbLink>
      <Media at="xs">
        <ProductListMobile
          products={products}
          horizontal={horizontal}
          ProductCard={ProductCard}
          height={height}
        />
      </Media>
      <Media greaterThan="xs">
        <ShowMoreLayout products={products} number={numberPerRow} />
      </Media>
    </Container>
  );
}
