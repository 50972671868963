import React from 'react';
import Link from 'next/link';

const DumbLink = React.forwardRef(({ children, ...props }: any, ref) => {
  // defaults prefetch to false if `prefetch` is not true
  // return <Link {...props} prefetch={props.prefetch ?? false} ref={ref} />;
  return (
    <Link {...props} ref={ref}>
      {children}
    </Link>
  );
});

DumbLink.displayName = 'DumbLink';

export default DumbLink;
